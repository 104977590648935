<h2>確認</h2>
<dl>
  <dt>ユーザーネーム</dt>
  <dd class="mat-h3">{{ data.username }}</dd>
  <dt>パスワード</dt>
  <dd class="mat-h3">********</dd>
  <dt>性別</dt>
  <dd class="mat-h3">{{ data.sex }}</dd>
  <dt>生年月日</dt>
  <dd class="mat-h3">{{ data.birthday | date: 'y年MM月dd日' }}</dd>
  <dt>住まい（地域）</dt>
  <dd class="mat-h3">{{ data.address }}</dd>
  <dt>通知</dt>
  <dd class="mat-h3">
    {{ data.notifyService === 'noService' ? 'なし' : data.notifyService }}
  </dd>
</dl>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">戻る</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="primary">
    送信
  </button>
</div>
