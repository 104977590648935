<mat-toolbar-row class="mat-elevation-z1 creator-profile">
  <button mat-icon-button (click)="close()" class="back-button">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div>{{ name }}</div>
</mat-toolbar-row>

<div class="main-content">
  <div class="icon-wrapper">
    <div
      [ngStyle]="{
        'background-image': 'url(' + creatorIcon + ')'
      }"
      class="creator-icon"
    ></div>
  </div>
</div>
<mat-divider></mat-divider>
<dl class="profile">
  <div>
    <dt class="mat-caption">
      プロフィール
    </dt>
    <dd>
      <p style="white-space: pre-line;">{{ profile }}</p>
    </dd>
  </div>
</dl>

<mat-divider
  *ngIf="live && liveState === 'done' && nextLiveSchedule !== null"
></mat-divider>
<dl
  *ngIf="live && liveState === 'done' && nextLiveSchedule !== null"
  class="next-live-schedule"
>
  <div>
    <dt class="mat-caption">
      次回配信予定
    </dt>
    <dd>
      <p>{{ nextLiveSchedule }}</p>
    </dd>
  </div>
</dl>

<mat-divider *ngIf="live"></mat-divider>
<dl *ngIf="live">
  <div>
    <dt class="mat-caption">
      ライブ配信時間
    </dt>
    <dd>
      <p>{{ liveTime }}</p>
    </dd>
  </div>
</dl>

<div *ngIf="urls?.length > 0">
  <mat-divider></mat-divider>
  <dl *ngIf="urls[0]">
    <div>
      <dt class="mat-caption">
        EC商品
      </dt>
      <dd style="justify-content: unset;" (click)="openEc(urls[0].url)" matRipple>
        <p>{{ urls[0].title }}</p>
      </dd>
    </div>
  </dl>
  <dl *ngIf="urls[1]">
    <div>
      <dt class="mat-caption">
      </dt>
      <dd style="justify-content: unset;" (click)="openEc(urls[1].url)" matRipple>
        <p>{{ urls[1].title }}</p>
      </dd>
    </div>
  </dl>
  <dl *ngIf="urls[2]">
    <div>
      <dt class="mat-caption">
      </dt>
      <dd style="justify-content: unset;" (click)="openEc(urls[2].url)" matRipple>
        <p>{{ urls[2].title }}</p>
      </dd>
    </div>
  </dl>
</div>

<div *ngIf="live?.reward.round1.type > 0">
  <dl>
    <div>
      <dt class="mat-caption">
        <p *ngIf="live.reward.round1.type === 1" style="transform: translateY(10px);">有料ライブチケット</p>
        <p *ngIf="live.reward.round1.type === 2" style="transform: translateY(10px);">デジタルコンテンツ</p>
      </dt>
      <dd style="justify-content: unset; align-items: center; padding-right: unset;" (click)="selectProduct(live.reward.round1.specId, rewardName1)" matRipple>
        <div style="width: 20%;">
          <img
            src="{{ rewardImage1 }}"
            style="object-fit: contain; width: 90%; height: 90%;"
          />
        </div>
        <p style="margin-left: 5px; font-size: smaller;">{{ rewardName1 }}({{ numOfPages1 }}ページ)<br>{{ live.reward.round1.threshold | number }}ポイント</p>
      </dd>
    </div>
  </dl>
</div>
<div *ngIf="live?.reward.round2.type > 0">
  <dl>
    <div>
      <dt class="mat-caption">
        <p *ngIf="live.reward.round2.type === 1" style="transform: translateY(10px);">有料ライブチケット</p>
        <p *ngIf="live.reward.round2.type === 2" style="transform: translateY(10px);">デジタルコンテンツ</p>
      </dt>
      <dd style="justify-content: unset; align-items: center; padding-right: unset;" (click)="selectProduct(live.reward.round2.specId, rewardName2)" matRipple>
        <div style="width: 20%;">
          <img
            src="{{ rewardImage2 }}"
            style="object-fit: contain; width: 90%; height: 90%;"
          />
        </div>
        <p style="margin-left: 5px; font-size: smaller;">{{ rewardName2 }}({{ numOfPages2 }}ページ)<br>{{ live.reward.round2.threshold | number }}ポイント</p>
      </dd>
    </div>
  </dl>
</div>
<div *ngIf="live?.reward.round3.type > 0">
  <dl>
    <div>
      <dt class="mat-caption">
        <p *ngIf="live.reward.round3.type === 1" style="transform: translateY(10px);">有料ライブチケット</p>
        <p *ngIf="live.reward.round3.type === 2" style="transform: translateY(10px);">デジタルコンテンツ</p>
      </dt>
      <dd style="justify-content: unset; align-items: center; padding-right: unset;" (click)="selectProduct(live.reward.round3.specId, rewardName3)" matRipple>
        <div style="width: 20%;">
          <img
            src="{{ rewardImage1 }}"
            style="object-fit: contain; width: 90%; height: 90%;"
          />
        </div>
        <p style="margin-left: 5px; font-size: smaller;">{{ rewardName3 }}({{ numOfPages3 }}ページ)<br>{{ live.reward.round3.threshold | number }}ポイント</p>
      </dd>
    </div>
  </dl>
</div>

<div style="height: 120px;"></div>
