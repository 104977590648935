import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CurrentUser, CurrentUserInfo } from '../models/app-models';

@Injectable({
  providedIn: 'root',
})
export class AppStoreService {
  // states
  private _isLoggedIn$: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);

  // live-viewer で使っているため、そのまま残す
  private _currentUser$: BehaviorSubject<CurrentUser | null> = new BehaviorSubject<CurrentUser | null>(
    null,
  );

  private _currentUserInfo$: BehaviorSubject<CurrentUserInfo | null> = new BehaviorSubject<CurrentUserInfo | null>(
    null,
  );

  // getters
  get isLoggedIn$(): Observable<boolean | null> {
    return this._isLoggedIn$.asObservable();
  }

  get currentUser$(): Observable<CurrentUser | null> {
    return this._currentUser$.asObservable();
  }

  get currentUserInfo$(): Observable<CurrentUserInfo | null> {
    return this._currentUserInfo$.asObservable();
  }

  get isAnonymous$(): Observable<boolean> {
    return of(false);
  }

  // change state
  userLoggedIn() {
    this._isLoggedIn$.next(true);
  }

  userLoggedOut() {
    this._isLoggedIn$.next(false);
  }

  saveCurrentUser(user: CurrentUser) {
    this._currentUser$.next(user);
  }

  clearCurrentUser() {
    this._currentUser$.next(null);
    localStorage.removeItem('userId');
  }

  setAnonymous() {}

  setSigned() {}

  saveAnonymousUser(user: CurrentUser) {
    localStorage.setItem('anonymousId', user.id);
  }

  saveCurrentUserInfo(user: CurrentUserInfo) {
    this._currentUserInfo$.next(user);
  }
}
