import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog-extended',
  templateUrl: './confirm-dialog-extended.component.html',
  styleUrls: ['./confirm-dialog-extended.component.scss'],
})
export class ConfirmDialogExtendedComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public message: any) {}

  ngOnInit(): void {}
}
