export const config = {
  apiKey: "AIzaSyCVeiTetNmLVli4LwPwA1B7ewUC8iminL8",
  authDomain: "i-pinx-dev.firebaseapp.com",
  databaseURL: "https://i-pinx-dev.firebaseio.com",
  projectId: "i-pinx-dev",
  storageBucket: "i-pinx-dev.appspot.com",
  messagingSenderId: "1025237566107",
  appId: "1:1025237566107:web:468f904c85cd198ca7fc3e",
  measurementId: 'G-0EEWL0KMHS',
};
