<mat-dialog-content class="mat-typography">
  <mat-radio-group
    color="primary"
    class="area-radio-group"
    [(ngModel)]="selectedArea"
  >
    <mat-radio-button
      class="area-radio-button"
      *ngFor="let area of areas"
      [value]="area"
    >
      {{ area }}
    </mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="initialSelectedArea">Cancel</button>
  <button mat-button [mat-dialog-close]="selectedArea">OK</button>
</mat-dialog-actions>
