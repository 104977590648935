import { Injectable } from '@angular/core';
import { AppStoreService } from '../stores/app-store.service';
import { CurrentUser, CurrentUserInfo } from '../models/app-models';
import { Observable, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoginService } from './login.service';
import { BottomNavigationStoreService } from '../stores/bottom-navigation-store.service';
import { HttpGatewayService } from 'src/app/gateway/http-gateway.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private appStore: AppStoreService,
    private httpGateway: HttpGatewayService,
  ) {}

  appReady$: Observable<boolean> = combineLatest(
    this.appStore.isLoggedIn$,
    this.appStore.currentUser$,
    (isLoggedIn, currentUser) => {
      if (isLoggedIn && currentUser) {
        // ログインしていて、かつuser 情報も取得済み
        return true;
      } else {
        // or ログイン中
        return false;
      }
    },
  );

  initialize() {
    this.ecAuth();
  }

  ecAuth() {
    this.httpGateway.fetchEcAuth().subscribe((result: CurrentUserInfo) => {
      // 失敗した場合はinterceptor で落ちるため、正常系のみ
      this.appStore.saveCurrentUser({ id: result.address });
      this.appStore.saveCurrentUserInfo(result);
      this.appStore.userLoggedIn();
    });
  }
}
