import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/my-profile', pathMatch: 'full' },
  {
    path: 'live',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    data: { path: 'home' },
  },
  // {
  //   path: 'search',
  //   loadChildren: () =>
  //     import('./features/home/home.module').then((m) => m.HomeModule),
  //   data: { path: 'search' },
  // },
  // {
  //   path: 'live',
  //   loadChildren: () =>
  //     import('./features/live/live.module').then((m) => m.LiveModule),
  // },
  {
    path: 'my-collection',
    loadChildren: () =>
      import('./features/my-collection/my-collection.module').then(
        (m) => m.MyCollectionModule,
      ),
  },
  {
    path: 'my-profile',
    loadChildren: () =>
      import('./features/my-profile/my-profile.module').then(
        (m) => m.MyProfileModule,
      ),
  },
  {
    path: 'store',
    loadChildren: () =>
      import('./features/content-store/content-store.module').then(
        (m) => m.ContentStoreModule,
      ),
  },
  // {
  //   path: 'notify',
  //   loadChildren: () =>
  //     import('./features/notify/notify.module').then((m) => m.NotifyModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
