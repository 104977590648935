import { config } from './firebase.config';

export const environment = {
  production: false,
  webSocket: 'https://live.piatec.co.th:1449',
  csWebSocket: 'https://live.piatec.co.th:1449',
  apiUrl: 'https://live.piatec.co.th:1449/ipinx/api/v1',
  ipfsUrl: 'https://dev-ipinx-app.japaneast.cloudapp.azure.com:441',
  shareUrl: 'https://live.piatec.co.th',
  hlsUrl: 'findfun.chat.ac:4043',
  archiveUrl: 'https://webrtc-dev1.ipinx-edge.site/record',
  rec_dir: '/home/y.erik.ohtake/record',
  janusUrl: 'wss://webrtc-dev1.ipinx-edge.site/ws',
  firebaseStorageUrl:
    'firebasestorage.googleapis.com/v0/b/findfun-e019f.appspot.com',
  firebaseConfig: config,
  oauthKey: '',
  entanext: {
    pointUrl: "https://livecommerce-ec.piatec.co.th/products/list?category_id=10",
    ecUrl: "https://livecommerce-ec.piatec.co.th/",
    wpUrl: "https://livecommerce-wp.piatec.co.th/",
    ecPtoductsUrl: "https://livecommerce-ec.piatec.co.th/products/list?category_id=12",
  }
};
