import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.scss'],
})
export class AddressSelectorComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) private data: string) {}

  areas: string[] = [
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
    'アイスランド',
    'アイルランド',
    'アゼルバイジャン',
    'アフガニスタン',
    'アメリカ合衆国',
    'アメリカ領ヴァージン諸島',
    'アメリカ領サモア',
    'アラブ首長国連邦',
    'アルジェリア',
    'アルゼンチン',
    'アルバ',
    'アルバニア',
    'アルメニア',
    'アンギラ',
    'アンゴラ',
    'アンティグア・バーブーダ',
    'アンドラ',
    'イエメン',
    'イギリス',
    'イギリス領インド洋地域',
    'イギリス領ヴァージン諸島',
    'イスラエル',
    'イタリア',
    'イラク',
    'イラン',
    'インド',
    'インドネシア',
    'ウォリス・フツナ',
    'ウガンダ',
    'ウクライナ',
    'ウズベキスタン',
    'ウルグアイ',
    'エクアドル',
    'エジプト',
    'エストニア',
    'エチオピア',
    'エリトリア',
    'エルサルバドル',
    'オーストラリア',
    'オーストリア',
    'オーランド諸島',
    'オマーン',
    'オランダ',
    'ガーナ',
    'カーボベルデ',
    'ガーンジー',
    'ガイアナ',
    'カザフスタン',
    'カタール',
    '合衆国領有小離島',
    'カナダ',
    'ガボン',
    'カメルーン',
    'ガンビア',
    'カンボジア',
    '北マリアナ諸島',
    'ギニア',
    'ギニアビサウ',
    'キプロス',
    'キューバ',
    'キュラソー',
    'ギリシャ',
    'キリバス',
    'キルギス',
    'グアテマラ',
    'グアドループ',
    'グアム',
    'クウェート',
    'クック諸島',
    'グリーンランド',
    'クリスマス島',
    'グルジア',
    'グレナダ',
    'クロアチア',
    'ケイマン諸島',
    'ケニア',
    'コートジボワール',
    'ココス諸島',
    'コスタリカ',
    'コモロ',
    'コロンビア',
    'コンゴ共和国',
    'コンゴ民主共和国',
    'サウジアラビア',
    'サウスジョージア・サウスサンドウィッチ諸島',
    'サモア',
    'サントメ・プリンシペ',
    'サン・バルテルミー',
    'ザンビア',
    'サンピエール島・ミクロン島',
    'サンマリノ',
    'サン・マルタン',
    'シエラレオネ',
    'ジブチ',
    'ジブラルタル',
    'ジャージー',
    'ジャマイカ',
    'シリア',
    'シンガポール',
    'シント・マールテン',
    'ジンバブエ',
    'スイス',
    'スウェーデン',
    'スーダン',
    'スヴァールバル諸島およびヤンマイエン島',
    'スペイン',
    'スリナム',
    'スリランカ',
    'スロバキア',
    'スロベニア',
    'スワジランド',
    'セーシェル',
    '赤道ギニア',
    'セネガル',
    'セルビア',
    'セントクリストファー・ネイビス',
    'セントビンセント・グレナディーン',
    'セントヘレナ・アセンションおよびトリスタンダクーニャ',
    'セントルシア',
    'ソマリア',
    'ソロモン諸島',
    'タークス・カイコス諸島',
    'タイ',
    '大韓民国',
    '台湾',
    'タジキスタン',
    'タンザニア',
    'チェコ',
    'チャド',
    '中央アフリカ共和国',
    '中国',
    'チュニジア',
    '朝鮮民主主義人民共和国',
    'チリ',
    'ツバル',
    'デンマーク',
    'ドイツ',
    'トーゴ',
    'トケラウ',
    'ドミニカ共和国',
    'ドミニカ国',
    'トリニダード・トバゴ',
    'トルクメニスタン',
    'トルコ',
    'トンガ',
    'ナイジェリア',
    'ナウル',
    'ナミビア',
    '南極',
    'ニウエ',
    'ニカラグア',
    'ニジェール',
    '日本',
    '西サハラ',
    'ニューカレドニア',
    'ニュージーランド',
    'ネパール',
    'ノーフォーク島',
    'ノルウェー',
    'ハード島とマクドナルド諸島',
    'バーレーン',
    'ハイチ',
    'パキスタン',
    'バチカン',
    'パナマ',
    'バヌアツ',
    'バハマ',
    'パプアニューギニア',
    'バミューダ',
    'パラオ',
    'パラグアイ',
    'バルバドス',
    'パレスチナ',
    'ハンガリー',
    'バングラデシュ',
    '東ティモール',
    'ピトケアン',
    'フィジー',
    'フィリピン',
    'フィンランド',
    'ブータン',
    'ブーベ島',
    'プエルトリコ',
    'フェロー諸島',
    'フォークランド諸島',
    'ブラジル',
    'フランス',
    'フランス領ギアナ',
    'フランス領ポリネシア',
    'フランス領南方・南極地域',
    'ブルガリア',
    'ブルキナファソ',
    'ブルネイ',
    'ブルンジ',
    'ベトナム',
    'ベナン',
    'ベネズエラ',
    'ベラルーシ',
    'ベリーズ',
    'ペルー',
    'ベルギー',
    'ポーランド',
    'ボスニア・ヘルツェゴビナ',
    'ボツワナ',
    'BES諸島',
    'ボリビア',
    'ポルトガル',
    '香港',
    'ホンジュラス',
    'マーシャル諸島',
    'マカオ',
    'マケドニア共和国',
    'マダガスカル',
    'マヨット',
    'マラウイ',
    'マリ',
    'マルタ',
    'マルティニーク',
    'マレーシア',
    'マン島',
    'ミクロネシア連邦',
    '南アフリカ',
    '南スーダン',
    'ミャンマー',
    'メキシコ',
    'モーリシャス',
    'モーリタニア',
    'モザンビーク',
    'モナコ',
    'モルディブ',
    'モルドバ',
    'モロッコ',
    'モンゴル',
    'モンテネグロ',
    'モントセラト',
    'ヨルダン',
    'ラオス',
    'ラトビア',
    'リトアニア',
    'リビア',
    'リヒテンシュタイン',
    'リベリア',
    'ルーマニア',
    'ルクセンブルク',
    'ルワンダ',
    'レソト',
    'レバノン',
    'レユニオン',
    'ロシア',
  ];

  initialSelectedArea: string;
  selectedArea: string;

  ngOnInit(): void {
    this.initialSelectedArea = this.data;
    this.selectedArea = this.data;
  }
}
