import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { bottomNavigationDestinations } from '../../models/app-models';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface Destination {
  destination: bottomNavigationDestinations;
  iconName: string;
}

@Component({
  selector: 'app-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss'],
})
export class BottomNavigationComponent implements OnInit {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'px05',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/original-icons/px05.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'edit',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/original-icons/edit.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'profile',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/original-icons/profile.svg',
      ),
    );
  }

  @Input() activeDestination: bottomNavigationDestinations;
  @Input() hidden: boolean;

  @Output() changeDestination: EventEmitter<
    bottomNavigationDestinations
  > = new EventEmitter<bottomNavigationDestinations>();

  ngOnInit(): void {}
}
