import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpGatewayService } from 'src/app/gateway/http-gateway.service';
import { CreatorProfileComponent } from 'src/app/shared/components/creator-profile/creator-profile.component';

@Component({
  selector: 'app-follower-list',
  templateUrl: './follower-list.component.html',
  styleUrls: ['./follower-list.component.scss'],
})
export class FollowerListComponent {
  title: string;
  isCreator: boolean;
  userList: any;
  userId: string;

  fullScreenDialogConfig: MatDialogConfig = {
    maxHeight: '100vh',
    height: '100vh',
    maxWidth: '100vw',
    width: '100vw',
    role: 'dialog',
    panelClass: 'full-screen',
    autoFocus: false,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      userId: string;
      isCreator: boolean;
    },
    private dialog: MatDialog,
    private httpGateway: HttpGatewayService,
  ) {
    this.userId = this.data.userId;
    this.isCreator = this.data.isCreator;
  }

  async ngOnInit() {
    if (this.isCreator) {
      // フォロワーリストを取得
      this.title = 'フォロワー';
      this.userList = await this.httpGateway.fetchFollower(this.userId).toPromise();
    } else {
      // お気に入りリストを取得
      this.title = 'お気に入り';
      this.userList = await this.httpGateway.fetchFollowee(this.userId).toPromise();
    }
  }

  openCreatorProfile(user: any) {
    const dialogRef = this.dialog.open(CreatorProfileComponent, {
      ...this.fullScreenDialogConfig,
      data: {
        userId: this.userId,
        creatorId: user.followee ? user.followee : user.follower,
        liveId: null,
        fromLive: false,
      }
    });
  }
}
