<mat-toolbar-row class="mat-elevation-z1 creator-profile">
  <button mat-icon-button [mat-dialog-close]="" class="back-button">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div>I’pinxの使い方</div>
</mat-toolbar-row>

<div style = "background-color:rgb(255, 235, 235); padding-top: 66px;">
		<h1 style="color:deeppink;  margin: 20px;" >
			I’pinxの使い方(記事作成中)
		</h1>
		<div style="margin: 40px;">
			<ul>
				<li>コンテンツの作り方</li>
				<li>ライブ配信の仕方</li>
				<li>クリエイターを検索する方法</li>
				<li>プロフィールの更新方法</li>
				<li>ポイントの購入方法</li>
			</ul>
		</div>
		<br>
		<br>
		<hr class="cp_hr" />
		<h1 style="color:deeppink;  margin: 20px;" >
			ライブストリーミング配信及びコンテンツ作成に関するガイドライン
		</h1>
		<div style="margin: 40px;">
			<p style="font-size:12px">
				本ガイドラインは、SingulaNet株式会社（以下、「当社」といいます。）が別途定める
				I’pinx会員規約、I’pinxライブストリーミング配信及びコンテンツ作成に関する規約に定める個別規約です。（以下、ルール等」といいます。）
				I’pinx会員規約・I’pinxライブストリーミング配信及びコンテンツ作成に関する規約で定めていないものでも、I’pinx会員は本ガイドラインが定めるルールを優先的に従うものとします。
			</p>
			<br>
			<h2 style="color:deeppink;">第1条　アカウント登録</h2>
			<ul>
				<li type="disc">本サービスを利用してライブストリーミング配信・コンテンツ作成しようとする方は、<strong style="color:firebrick">クリエイターとして登録する</strong>必要があります。</li>
				<li type="disc">配信者になろうとする方は、I’pinxライブストリーミング配信及びコンテンツ作成に関する規約を承認の上、当社の定める手続きにより当社に登録を申し込み（以下、「登録申請」といいます。）当社の承諾を得なければならないものとします。</li>
				<li type="disc">配信者になろうとする方が<strong style="color:firebrick">未成年</strong>である場合は、ライブストリーミング配信・コンテンツ作成機能を利用することについて、法定代理人の同意を得るものとします。</li>
				<li type="disc">当社は、登録申請の承諾をした場合でも、配信者が実施するライブストリーミング配信・コンテンツ作成について何ら責任を負わないものとします。</li>
				<li type="disc">当社は、上記の登録申請の承諾をした場合でも、当該承諾を撤回することができるものとします。</li>
				<li type="disc">配信者は、<strong style="color:firebrick">I’pinxライブストリーミング配信及びコンテンツ作成に関する規約、本ガイドライン・その他当社が定める利用条件を遵守する</strong>ものとし、配信者によるライブストリーミング配信・コンテンツ作成、その他の行為についてすべて責任を負うものとします。</li>
				<li type="disc">配信者は、登録申請にあたり、当社の定める情報を登録する必要があります。これらの情報は、本サービス内において表示されることがありますが、外部に無断で公開することはありません。</li>
			</ul>
			<br>
			<h2 style="color:deeppink;">第2条　ライブストリーミング・コンテンツ作成配信に関する禁止事項</h2>
			<div>
				クリエイターは、I’pinxライブストリーミング配信及びコンテンツ作成に関する規約で禁止される事項の他、以下のライブストリーミング配信及びコンテンツ作成等することができません。
			</div>
			<ul>
				<li type="disc">第三者の<strong style="color:firebrick">名誉・信頼・プライバシーを侵害する</strong>行為</li>
				<li type="disc">第三者を繰り返し<strong style="color:firebrick">差別・誹謗中傷・脅迫・ストーカーするなど</strong>の行為</li>
				<li type="disc"><strong style="color:firebrick">第三者を不快にさせる</strong>すべての行為</li>
				<li type="disc"><strong style="color:firebrick">自他問わず個人情報を公開する行為・開示を促す</strong>行為</li>
				<li type="disc"><strong style="color:firebrick">内外の国家・民族などの尊厳を傷つけるおそれ</strong>があるもの</li>
				<li type="disc"><strong style="color:firebrick">国際親善を害するおそれ</strong>のあるもの</li>
				<li type="disc"><strong style="color:firebrick">公序良俗に反する</strong>もの　</li>
				<li type="disc"><strong style="color:firebrick">アダルト、風俗関係</strong>のもの</li>
						（例）わいせつ物、ポルノ、アダルトグッズ、アダルトビデオ、アダルトゲーム等<br>
				<li type="disc"><strong style="color:firebrick">性的表現またはヌード画像</strong>を含むもの</li>
					（例）ヌード写真や性行為を連想させるイラスト等すべてコンテンツ<br>
				<li type="disc"><strong style="color:firebrick">未成年による飲酒や喫煙またはそれらを助長する</strong>もの</li>
				<li type="disc"><strong style="color:firebrick">暴力、とばく、麻薬、売春などを肯定又は美化</strong>するもの　</li>
				<li type="disc"><strong style="color:firebrick">自殺関与（誘引、勧誘、協力、教唆）に相当する</strong>内容</li>
				<li type="disc">武器などの<strong style="color:firebrick">危険物所持</strong></li>
				<li type="disc">異性・同性問わず、<strong style="color:firebrick">交際を求める内容の配信・コンテンツ作成</strong></li>
				<li type="disc">異性・同性問わず、<strong style="color:firebrick">交際の求める内容の配信・コンテンツに応じようとする</strong>行為</li>
				<li type="disc">異性・同性を問わず、<strong style="color:firebrick">わいせつ行為、売買春・援助交際を目的とし、または誘引する</strong>作品</li>
				<li type="disc">映像や音楽など<strong style="color:firebrick">著作権のあるものの無断配信</strong></li>
				（例）テレビ番組・映画・アニメ・音楽・有料配信動画など<br>
				<li type="disc"><strong style="color:firebrick">配信が禁止されている場所</strong>からの配信</li>
				（例）レジャー施設・スポーツ会場・ライブイベント・カラオケルーム<br>
				<li type="disc">長時間、視聴者とコミュニケーションを取らない<strong style="color:firebrick">放置配信</strong></li>
				（例）寝落ちや長時間のながら配信<br>
				<li type="disc"><strong style="color:firebrick">犯罪その他の法令違反行為を誘発</strong>し、または助長・促進する恐れのあるもの</li>
				<li type="disc"><strong style="color:firebrick">交通安全に違反する</strong>配信</li>
				（例）運転しながらの配信・安全を脅かすもの<br>
				<li type="disc"><strong style="color:firebrick">責任の所在が明確でない</strong>もの</li>
				（例）責任の所在が明らかでない内容の配信・コンテンツ作成
				<li type="disc">当社サービスに<strong style="color:firebrick">過度の負荷をかける</strong>もの</li>
				<li type="disc">第三者または団体に<strong style="color:firebrick">なりすます</strong>すべての行為　</li>
				<li type="disc">特定の商品やサービスを<strong style="color:firebrick">宣伝目的で勧誘する</strong>行為</li>
				<li type="disc"><strong style="color:firebrick">外部リンクがライブストリーミング配信の主たる内容</strong>とみなされるもの</li>
				<li type="disc"><strong style="color:firebrick">カルト的宗教活動、布教活動に関わるもの、過度な政治活動を賛美・助長する</strong>作品</li>
				<li type="disc"><strong style="color:firebrick">事実誤認を生じさせ</strong>、またはそのおそれのあるもの</li>
				<li type="disc"><strong style="color:firebrick">潜在意識に働きかける表現</strong>を用いたもの</li>
				<li type="disc"><strong style="color:firebrick">スパム等迷惑行為</strong>を行なっているまたはそのおそれがあるもの</li>
				<li type="disc">ライブストリーミング配信の<strong style="color:firebrick">提供主体や目的が不明、または曖昧</strong>なもの</li>
				<li type="disc"><strong style="color:firebrick">他人の名義を語る</strong>もの</li>
				<li type="disc"><strong style="color:firebrick">本サービスの仕様を解析する</strong>ことが主目的のもの</li>
				<li type="disc">配信者は、配信するライブストリーミング及びコンテンツにおいて、当社が特に認める場合を除き、性的な表現が要素として含まれる等、<strong style="color:firebrick">品位を損なうまたはI’pinx会員が不快に感じる</strong>おそれがある表現を行ってはなりません。</li>
				<li type="disc">性に関する表現で、<strong style="color:firebrick">性行為を連想させる</strong>画像、写真、動画、文言</li>
				<li type="disc">画像、音声、文言等の組み合わせにより、<strong style="color:firebrick">性的な関係を中心としたストーリー</strong>であることを想像させるもの</li>
				<li type="disc">被写体が明らかに未成年者と判断できる、またはまぎらわしい作品の投稿。<strong style="color:firebrick">児童ポルノ</strong>の疑いがあるもの</li>
				<li type="disc">人体の<strong style="color:firebrick">局部を過度に強調</strong>したもの</li>
				<li type="disc">人間、動物に限らず全ての<strong style="color:firebrick">性器及び性器を連想する</strong>もの</li>
				<li type="disc"><strong style="color:firebrick">ネガティブな表現にて人体の特徴</strong>を現したもの　</li>
				<li type="disc"><strong style="color:firebrick">醜悪、残酷、猟奇的で不快感</strong>を与えるおそれがあるもの</li>
				<li type="disc"><strong style="color:firebrick">糞便を排出</strong>し、コンテンツとして扱う作品の投稿</li>
				<li type="disc"><strong style="color:firebrick">恐怖心を強く喚起する</strong>もの</li>
				<li type="disc">ケンカや格闘行為の<strong style="color:firebrick">過激な描写、行為を肯定する</strong>もの</li>
				<li type="disc"><strong style="color:firebrick">人身売買</strong>もしくはそれを想像させるもの</li>
				<li type="disc">人種、性別、年齢等、身体特長による<strong style="color:firebrick">差別</strong>につながるもの</li>
				<li type="disc"><strong style="color:firebrick">他人を誹謗、中傷もしくは侮辱</strong>するもの、他人の名誉や信用を傷つけるもの</li>
				<li type="disc"><strong style="color:firebrick">被写体を誹謗中傷する目的</strong>で配信・作成された肖像権、意匠権を侵害するもの</li>
				<li type="disc"><strong style="color:firebrick">被写体または撮影者の許可を得ていない</strong>作品の配信・コンテンツ作成、第三者の著作権・プライバシーを侵害する行為</li>
				<li type="disc">他人の宗教、思想、信条を<strong style="color:firebrick">過度に否定</strong>するもの</li>
				<li type="disc">配信者は、いかなる場合でも、ライブストリーミング配信及びコンテンツ作成をI’pinx,当社または無関係の第三者が、<strong style="color:firebrick">公認、支持または提供していると誤認させる表示</strong>を行ってはなりません。</li>
				<li type="disc"><strong style="color:firebrick">18歳未満</strong>のI’pinx会員は、時間帯を問わずライブストリーミング配信・コンテンツ作成を実施してはなりません。</li>
				18歳の配信者は、22時以降から翌日5時までの間、ライブストリーミング配信・コンテンツ作成を実施してはなりません。
				年齢によるライブストリーミング配信・コンテンツ作成の実施制限は、該当する年齢の誕生日当日0時に解除されます。<br>
			</ul>
			<br>
			<h2 style="color:deeppink;">第3条　掲載箇所</h2>
			<ul>
				<li type="disc">当社は、ライブストリーミング配信・コンテンツ作成へのリンクを本サービス内の任意の箇所に掲載することができるものとしますが、掲載を保証するものではありません。
					また、掲載された場合であっても掲載箇所が変更となる場合があります。</li>
				<li type="disc">当社は、ライブストリーミング配信・コンテンツ作成で掲載箇所及び／または掲載箇所に近接する箇所に配信者が当社に登録した情報を表示することができるものとします。</li>
			</ul>
			<br>
			<h2 style="color:deeppink;">第4条　ユーザサポート</h2>
			<ul>
				<li type="disc">当社がI’pinx会員から、ライブストリーミング配信や配信者が本サービス内で記述情報に関して問い合わせを受けた場合、当社が当該問い合わせに関して配信者に対して問い合わせを行うことがあります。
					その場合配信者は、当社からの問い合わせについて、当社が定める期間内に適切な対応を行わなくてはなりません。</li>
				<li type="disc">クリエイターは、他の会員が提供等するライブストリーミング配信・コンテンツ作成において、I’pinx会員規約および、ストリーミング配信・コンテンツ作成に関する規約、
					本ガイドラインへの違反を発見した場合は、当社が提供する通報機能を用いてすみやかに当社へ連絡するものとします。</li>
			</ul>
			<br>
			<h2 style="color:deeppink;">第5条　当社が提供するデータの目的外利用</h2>
			<ul>
				<li type="disc">クリエイターは、本サービスで取得した当社が提供する各種データを本サービス利用以外の目的で利用してはなりません。</li>
			</ul>
			<br>
			<h2 style="color:deeppink;">第6条　外部リンク</h2>
			<ul>
				<li type="disc">配信者は、I’pinxライブストリーミング配信・コンテンツ作成に関する規約及び本ガイドラインにて禁止している表現内容を含む外部サイトに遷移する外部リンクを記述することはできません。</li>
			</ul>
		</div>
    <div style="height: 60px;"></div>
	</div>
