import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HttpGatewayService } from 'src/app/gateway/http-gateway.service';
import { ConfirmDialogExtendedComponent } from 'src/app/shared/components/confirm-dialog-extended/confirm-dialog-extended.component';

@Component({
  selector: 'app-creator-profile',
  templateUrl: './creator-profile.component.html',
  styleUrls: ['./creator-profile.component.scss'],
})
export class CreatorProfileComponent {
  fullScreenDialogConfig: MatDialogConfig = {
    maxHeight: '100vh',
    height: '100vh',
    maxWidth: '100vw',
    width: '100vw',
    role: 'dialog',
    panelClass: 'full-screen',
    autoFocus: false,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      userId: string;
      creatorId: string;
      liveId: string;
      liveState: 'doing' | 'done';
    },
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private httpGateway: HttpGatewayService,
    private dialogRef: MatDialogRef<CreatorProfileComponent>,
    private router: Router,
  ) {
    this.userId = this.data.userId;
    this.creatorId = this.data.creatorId;
    this.liveState = this.data.liveState;
    this.result = [];

    this.httpGateway
      .fetchUserInfo(this.data.creatorId)
      .subscribe((creator: any) => {
        this.name = creator.nickname;
        this.icon = creator.creatorIconUrl;
        this.profile = creator.profile;
        this.tags = creator.tags;
        this.nextLiveSchedule =
          creator.nextLiveSchedule !== null && creator.nextLiveSchedule.length > 0
            ? this.formatNextLiveSchedule(creator.nextLiveSchedule)
            : null;
      });

    this.httpGateway.fetchFollower(this.creatorId).subscribe((follower) => {
      this.follower = follower;
    });

    this.httpGateway.fetchFollowee(this.data.userId).subscribe((followee) => {
      for (const creator of followee) {
        if (creator.followee === this.creatorId) {
          this.isFollowee = true;
          this.initValue = true;
        }
      }
    });

    if (this.data.liveId) {
      this.httpGateway.fetchLiveProductUrls(this.data.liveId)
        .subscribe((urls) => {
          this.urls = urls.urls;
        });
    }

    if (this.data.liveId && this.liveState === 'doing') {
      this.httpGateway.fetchLive(this.data.liveId).subscribe((live) => {
        this.initLiveInfo(live);
      });
    }

    if (this.data.liveId && this.liveState === 'done') {
     this.httpGateway.fetchLiveArchive(this.data.liveId).subscribe((live) => {
       this.initLiveInfo(live);
     });
   }

  }

  isFollowee = false;
  initValue = false;
  creatorId: string;
  userId: string;
  name: string;
  icon: string;
  profile: string;
  live: any;
  rewardName1: string;
  rewardName2: string;
  rewardName3: string;
  rewardImage1: string;
  rewardImage2: string;
  rewardImage3: string;
  numOfPages1: number;
  numOfPages2: number;
  numOfPages3: number;
  followee: any[];
  follower: any[];
  liveTime: any;
  result: any;
  tags: string[];
  urls: any;
  liveState: 'doing' | 'done';
  nextLiveSchedule: string;

  initLiveInfo(live) {
    this.live = live;
    const createdAt = new Date(live.createdAt);
    const current = new Date();
    const diff = current.getTime() - createdAt.getTime();
    let _diffSecond = Math.floor(diff / 1000);
    let _diffMinute = Math.floor(_diffSecond / 60);
    let diffHour = Math.floor(_diffMinute / 60);
    let diffMinute = _diffMinute - diffHour * 60;
    let diffSecond = _diffSecond - _diffMinute * 60;
    this.liveTime =
      (diffHour < 10 ? '0' + diffHour : diffHour) +
      ':' +
      (diffMinute < 10 ? '0' + diffMinute : diffMinute) +
      ':' +
      (diffSecond < 10 ? '0' + diffSecond : diffSecond);

    if (this.live.reward.round1.type > 0) {
      const rewardSpec1 = this.httpGateway
        .fetchContentSpec(this.live.reward.round1.specId)
        .subscribe((spec1) => {
          this.rewardName1 = spec1.name;
          this.rewardImage1 = spec1.data[0].image1;
          this.numOfPages1 = spec1.data.length;
        });
    }
    if (this.live.reward.round2.type > 0) {
      const rewardSpec2 = this.httpGateway
        .fetchContentSpec(this.live.reward.round2.specId)
        .subscribe((spec2) => {
          this.rewardName2 = spec2.name;
          this.rewardImage2 = spec2.data[0].image1;
          this.numOfPages2 = spec2.data.length;
        });
    }
    if (this.live.reward.round3.type > 0) {
      const rewardSpec3 = this.httpGateway
        .fetchContentSpec(this.live.reward.round3.specId)
        .subscribe((spec3) => {
          this.rewardName3 = spec3.name;
          this.rewardImage3 = spec3.data[0].image1;
          this.numOfPages3 = spec3.data.length;
        });
    }
  }

  get creatorIcon() {
    return this.icon ? this.icon : 'assets/imgs/creator-icon.png';
  }

  formatNextLiveSchedule(schedule: string) {
    const date = new Date(schedule);
    return `${date.getFullYear()}年${
      date.getMonth() + 1
    }月${date.getDate()}日${date.getHours()}時${date.getMinutes()}分`;
  }

  followOn() {
    this.httpGateway
      .postFollow(this.userId, this.creatorId)
      .subscribe((result) => {
        this.isFollowee = true;
        this.result = {
          initValue: this.initValue,
          isFollowee: true,
          creatorName: this.name,
        };
      });
  }

  followOff() {
    this.httpGateway
      .postFollow(this.userId, this.creatorId)
      .subscribe((result) => {
        this.isFollowee = false;
        this.result = {
          initValue: this.initValue,
          isFollowee: false,
          creatorName: this.name,
        };
      });
  }

  openCreatorProfile(user: any) {
    const dialogRef = this.dialog.open(CreatorProfileComponent, {
      ...this.fullScreenDialogConfig,
      data: {
        userId: this.userId,
        creatorId: user.followee ? user.followee : user.follower,
        liveId: null,
      },
    });
  }

  close() {
    if (this.liveState === 'doing') {
      this.dialogRef.close(this.result);
    } else {
      this.router.navigateByUrl('/my-profile').then(() => {
        this.dialogRef.close();
      });
    }
  }

  openEc(url: string) {
    window.open(url, '_blank');
  }

  selectProduct(specId: string, specName: string) {
    const dialogRef = this.dialog.open(ConfirmDialogExtendedComponent, {
      minWidth: '90vw',
      data: {
        main: `「${specName}」を購入しますか？`,
        sub: null,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        // 購入
        this.httpGateway
          .postPurchasesProduct(this.userId, specId, this.data.liveId)
          .subscribe((result) => {
            if (result.error) {
              this.snackBar.open(result.error, undefined, { duration: 1500 });
            } else {
              this.snackBar.open('購入しました', undefined, { duration: 1500 });
              this.result.push({
                specName,
              });
            }
          });
      }
    })
  }

}
