import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sign-up-confirm-dialog',
  templateUrl: './sign-up-confirm-dialog.component.html',
  styleUrls: ['./sign-up-confirm-dialog.component.scss'],
})
export class SignUpConfirmDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {}
}
