import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TopAppBarComponent } from './shared/components/top-app-bar/top-app-bar.component';
import { BottomNavigationComponent } from './shared/components/bottom-navigation/bottom-navigation.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatRippleModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
  DateAdapter,
} from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';

// Hammerjs
import {
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { CreatorProfileComponent } from './shared/components/creator-profile/creator-profile.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AddressSelectorComponent } from './shared/components/address-selector/address-selector.component';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConfirmDialogDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogExtendedComponent } from './shared/components/confirm-dialog-extended/confirm-dialog-extended.component';
import { ErrorDialogComponent } from './shared/components/error-dialog/error-dialog.component';
import { LoginErrorDialogComponent } from './shared/components/login-error-dialog/login-error-dialog.component';
import { SignUpConfirmDialogComponent } from './shared/components/sign-up-confirm-dialog/sign-up-confirm-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { HowToUseComponent } from './shared/components/how-to-use/how-to-use.component';
import { FollowerListComponent } from './shared/components/follower-list/follower-list.component';

import * as firebase from 'firebase/app';
import { HttpInterceptorService } from './gateway/http-interceptor.service';
firebase.initializeApp(environment.firebaseConfig);

declare let OAuth: any;
OAuth.initialize(environment.oauthKey);

export class JPDateAdapter extends NativeDateAdapter {
  getDateNames(): string[] {
    return Array.from(Array(31), (v, k) => `${k + 1}`);
  }
}

@Injectable()
export class MatGestureConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new (window as any).Hammer(element);
    for (const eventName of Object.keys(this.overrides)) {
      mc.get(eventName).set(this.overrides[eventName]);
    }
    mc.get('swipe').set({ direction: Hammer.DIRECTION_ALL });
    mc.get('pan').set({ direction: Hammer.DIRECTION_ALL });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    TopAppBarComponent,
    BottomNavigationComponent,
    AddressSelectorComponent,
    ConfirmDialogDialogComponent,
    ConfirmDialogExtendedComponent,
    ErrorDialogComponent,
    LoginErrorDialogComponent,
    SignUpConfirmDialogComponent,
    CreatorProfileComponent,
    HowToUseComponent,
    FollowerListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    HammerModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSnackBarModule,
    MatToolbarModule,
    OverlayModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: MatGestureConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: DateAdapter, useClass: JPDateAdapter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
