import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import {
  Article,
  LiveListItem,
  CurrentUserInfo,
  ContentInfo,
  UserInfo,
  LoginSuccess,
  LoginFailed,
  NotifyArticleContent,
  EcProducts,
} from '../shared/models/app-models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpGatewayService {
  constructor(private http: HttpClient) {}

  fetchBanners(): Observable<Article[]> {
    const banners: Article[] = [
      {
        id: '1',
        category: 'banner',
        title: 'banner_official_ipinx',
        imgUrl: 'assets/imgs/twitter.ping.png',
        isExternal: true,
        url: 'https://twitter.com/official_ipinx',
      },
      {
        id: '2',
        category: 'banner',
        title: 'banner_hp',
        imgUrl: 'assets/imgs/banner_hp.png',
        isExternal: true,
        url: 'https://ipinx-edge.jp/',
      },
      {
        id: '3',
        category: 'banner',
        title: 'banner_manual',
        imgUrl: 'assets/imgs/banner_manual.png',
        isExternal: true,
        url: 'https://ipinx-edge.jp/manual/',
      },
    ];

    return of(banners);
  }

  /**
   * ライブ録画一覧を取得する
   */
  fetchLiveArchives(userId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/${userId}/archives`);
  }

  /**
   * 限定ライブ配信で表示中コンテンツ（あれば）の情報を取得する
   */
  fetchLiveContents(liveId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/live-contents/${liveId}`);
  }

  fetchLiveCategories(): Observable<string[]> {
    const categories: string[] = [
      '登録クリエイター',
      // 'フォロー',
      // '新人',
      //      '料理',
      //      'コスプレイヤー',
      //      'グラビアアイドル',
    ];

    return of(categories);
  }

  /**
   * コンテンツを獲得済みか確認する
   */
  fetchContentPermission(
    userId: string,
    specId: string,
    liveId: string,
  ): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/users/${userId}/content-permission/${specId}/${liveId}`,
    );
  }

  /**
   * コンテンツの閲覧権限を確認する
   */
  fetchViewingAuthority(objectId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/contents/permission/${objectId}`);
  }

  /**
   * コンテンツ仕様を取得する
   */
  fetchContentSpec(specId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/content-specs/${specId}`);
  }

  /**
   * クリエイターの一覧を取得する
   */
  fetchCreators(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/creators`);
  }

  /**
   * フォロー中のクリエイターの一覧を取得する
   */
  fetchFollowee(userId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/${userId}/followee`);
  }

  /**
   * フォロワーの一覧を取得する
   */
  fetchFollower(userId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/${userId}/follower`);
  }

  /**
   * ライブ情報を取得する
   */
  fetchLive(liveId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lives/${liveId}`);
  }

  /**
   * クリエイター指定で配信中ライブ情報を取得する
   */
  fetchLivebyCreatorId(creatorId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/creators/${creatorId}/live`);
  }

  /**
   * 終了したライブの情報を取得する
   */
  fetchLiveArchive(liveId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lives/${liveId}/archive`);
  }

  /**
   * ライブ視聴権限を取得する
   */
  fetchLivePermission(liveId: string, userId: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/users/${userId}/live-permission/${liveId}`,
    );
  }

  /**
   * 配信中のライブ情報一覧を取得する
   */
  fetchLives(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lives`);
  }

  /**
   * 配信中のお気に入りライブ情報を取得する
   */
  fetchFollowLives(userId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/${userId}/lives`);
  }

  /**
   * ライブ配信設定を取得する
   */
  fetchLiveSetting(userId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/${userId}/live-settings`);
  }

  /**
   * ライブのタグのみ取得する
   */
  fetchLiveTags(liveId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lives/${liveId}/tags`);
  }

  /**
   * ライブのEC商品情報（URL直接入力）を取得する
   */
  fetchLiveProductUrls(liveId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lives/${liveId}/urls`);
  }

  /**
   * 次のライブ情報を取得する
   */
  fetchNextLive(liveId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lives/${liveId}/next`);
  }

  /**
   * 前のライブ情報を取得する
   */
  fetchPrevLive(liveId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lives/${liveId}/prev`);
  }

  /**
   * クリエイターが作成、発行したコンテンツ一覧情報を取得する
   */
  fetchOwnedContentSpecs(
    userId: string,
    state?: number,
  ): Observable<ContentInfo[]> {
    if (state !== undefined) {
      return this.http.get<ContentInfo[]>(
        `${environment.apiUrl}/users/${userId}/content-specs/${state}`,
      );
    } else {
      return this.http.get<ContentInfo[]>(
        `${environment.apiUrl}/users/${userId}/content-specs`,
      );
    }
  }

  /**
   * リスナーがライブで獲得したリワード一覧情報を取得する
   */
  fetchRewards(liveId: string, userId: string): Observable<ContentInfo[]> {
    return this.http.get<ContentInfo[]>(
      `${environment.apiUrl}/users/${userId}/rewards/${liveId}`,
    );
  }

  /**
   * IceServer情報を取得する
   */
  fetchIceServers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/iceservers`);
  }

  /**
   * リスナーが獲得した全リワード一覧情報を取得する
   */
  fetchAllRewards(userId: string): Observable<ContentInfo[]> {
    return this.http.get<ContentInfo[]>(
      `${environment.apiUrl}/users/${userId}/rewards`,
    );
  }

  /**
   * WebRTCサーバの接続先（index）を取得する
   */
  fetchServerIndex(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lives/serverIndex`);
  }

  /**
   * 投げ銭アイテムの一覧を取得する
   */
  fetchTipItems(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/tip-items`);
  }

  /**
   * アニメーションスタンプの一覧を取得する
   */
  fetchTipStamps(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/tip-stamps`);
  }

  /**
   * ユーザー情報を取得する
   */
  fetchUserInfo(userId: string): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${environment.apiUrl}/users/${userId}`);
  }

  fetchTags(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/live-tags`);
    // const tags: string[] = ['hoge', 'fuga', 'foo', 'bar'];
    // return of(tags);
  }

  fetchNotify(userId: string): Observable<Article[]> {
    return this.http.get<any>(`${environment.apiUrl}/users/${userId}/articles`);
    /*
    return of([
      {
        id: '5',
        category: 'notify',
        title: '新機能をリリースしました。',
        isExternal: false,
        createdAt: '2020-08-14T03:00:00Z',
      },
    ]);
    */
  }

  fetchNotifyArticleContent(
    userId: string,
    id: number,
  ): Observable<NotifyArticleContent> {
    return this.http.get<any>(
      `${environment.apiUrl}/users/${userId}/articles/${id}`,
    );
    /*
    return of({
      title: '新機能をリリースしました。',
      article:
        "新機能をリリースしました。\n引き続きI'pinx をお楽しみください。",
    });
    */
  }

  fetchEcAuth(): Observable<CurrentUserInfo> {
    return this.http.get<CurrentUserInfo>(`${environment.apiUrl}/ec-auth`);
  }

  fetchEcProducts(): Observable<EcProducts[]> {
    return this.http.get<EcProducts[]>(`${environment.apiUrl}/products`);
  }

  fetchEcProductUrl(userId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/${userId}/products`);
  }

  fetchEcBalance(userId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/${userId}/balance`);
  }

  fetchPlayList(specId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/contents/playlist/${specId}`,
      { responseType: 'blob' as 'json' }
    );
  }

  /**
   * アバターを更新する
   */
  postAvater(userId: string, avatarId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/avatar`, {
      userId,
      avatarId,
    });
  }

  /**
   * コンテンツを一時保存する
   * contentIdが未設定の場合は登録、設定済みの場合は更新
   */
  postContent(
    specId: string,
    type: number,
    name: string,
    data: any,
    totalSupplyLimit: number,
    userId: string,
    tags: string[],
  ): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/contents`, {
      specId,
      type,
      name,
      data,
      totalSupplyLimit,
      userId,
      tags,
    });
  }

  /**
   * コンテンツを破棄する
   */
  postContentDiscard(specId: string, userId: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/contents/discard`, {
      specId,
      userId,
    });
  }

  /**
   * コンテンツを発行する
   * リワードに設定可能になる
   */
  postContentIssue(
    specId: string,
    totalSupplyLimit: number,
    amount: number,
    userId: string,
    tags: string[],
  ): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/contents/issue`, {
      specId,
      totalSupplyLimit,
      amount,
      userId,
      tags,
    });
  }

  /**
   * 映像のサムネイルを作成する
   * サムネイルのIPFSハッシュが返る
   */
  postContentPoster(hash: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/contents/poster`, {
      hash,
    });
  }

  /**
   * クリエイターアイコンを更新する
   */
  postCreatorIcon(userId: string, creatorIconUrl: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/creator-icon`, {
      userId,
      creatorIconUrl,
    });
  }

  /**
   * フォローするorフォロー解除
   */
  postFollow(userId: string, followee: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/follow`, {
      userId,
      followee,
    });
  }

  /**
   * ライブ配信設定を更新する
   */
  postLiveSetting(
    address: string,
    nickname: string,
    channelId: string,
    imageUrl: string,
    hasPrivate: boolean,
    reward: any,
    camera: any,
    archive: boolean,
    soundonly: boolean,
    cameraId: number,
    tags: string[],
  ): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/live-settings`, {
      address,
      nickname,
      channelId,
      imageUrl,
      hasPrivate,
      reward,
      camera,
      archive,
      soundonly,
      cameraId,
      tags,
    });
  }

  /**
   * DEBUGログを送信する
   */
  postLog(username: string, log: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/logs`, {
      user: username,
      log,
    });
  }

  /**
   * ERRORログを送信する
   */
  postErrorLog(username: string, log: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/logs`, {
      user: username,
      log,
      level: 'ERROR',
    });
  }

  /**
   * 匿名ユーザーでログインする
   */
  postAnonymousLogin(): Observable<any> {
    return this.http.post<LoginSuccess | LoginFailed>(
      `${environment.apiUrl}/anonymous-users`,
      {},
    );
  }

  /**
   * ログインする
   */
  postLogin(
    username: string,
    hash: string,
  ): Observable<LoginSuccess | LoginFailed> {
    return this.http.post<LoginSuccess | LoginFailed>(
      `${environment.apiUrl}/login`,
      {
        username,
        password: hash,
      },
    );
  }

  /**
   * ポイントを購入する
   */
  postMint(userId: string, amount: number) {
    return this.http.post<any>(`${environment.apiUrl}/users/mint`, {
      userId,
      amount,
    });
  }

  /**
   * プロフィールを更新する
   */
  postProfile(userId: string, profile: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/profile`, {
      userId,
      profile,
    });
  }

  /**
   * アカウントを登録する
   */
  postUser(
    username: string,
    hash: string,
    attribute: any,
    sns: any,
  ): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users`, {
      nickname: username,
      password: hash,
      attribute: attribute,
      sns: sns,
    });
  }

  /**
   * ユーザータグを更新する
   */
  postUserTag(userId: string, tags: string[]): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/tags`, {
      userId,
      tags,
    });
  }

  /**
   * 匿名ユーザーのライブ視聴時間を取得、更新する
   */
  postViewingTime(userId: string, time: number): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/anonymous-users/viewing-time`,
      {
        userId,
        time,
      },
    );
  }

  /**
   * SNS通知設定を変更する
   */
  postNotifyService(userId: string, info: any) {
    return this.http.post<any>(`${environment.apiUrl}/users/sns`, {
      userId,
      snsAccount: info,
    });
  }

  postPurchasesProduct(address: string, productId: string, liveId: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/purchases`, {
      liveId,
      address,
      type: 'content', // 'product' || 'content'
      itemId: productId,
    });
  }
}
