import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-how-to-use',
  templateUrl: './how-to-use.component.html',
  styleUrls: ['./how-to-use.component.scss'],
})
export class HowToUseComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
