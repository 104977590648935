<mat-toolbar [class.hidden]="hidden" class="bottom-navigation">
  <div
    matRipple
    class="nav-wrapper"
    [class.active]="activeDestination === 'home'"
    (click)="changeDestination.emit('home')"
  >
    <mat-icon class="nav-icon">home</mat-icon>
    <div class="mat-caption">ホーム</div>
  </div>
  <div
    matRipple
    class="nav-wrapper"
    [class.active]="activeDestination === 'search'"
    (click)="changeDestination.emit('search')"
  >
    <mat-icon class="nav-icon">search</mat-icon>
    <div class="mat-caption">見つける</div>
  </div>
  <div
    matRipple
    class="nav-wrapper"
    [class.active]="activeDestination === 'live'"
    (click)="changeDestination.emit('live')"
  >
    <mat-icon svgIcon="px05" aria-hidden="false" class="nav-icon"></mat-icon>
    <div class="mat-caption live-color">ライブ</div>
  </div>
  <div
    matRipple
    class="nav-wrapper"
    [class.active]="activeDestination === 'content-editor'"
    (click)="changeDestination.emit('content-editor')"
  >
    <mat-icon svgIcon="edit" aria-hidden="false" class="nav-icon"></mat-icon>
    <div class="mat-caption content-editor-color">作る</div>
  </div>
  <div
    matRipple
    class="nav-wrapper"
    [class.active]="activeDestination === 'my-collection'"
    (click)="changeDestination.emit('my-collection')"
  >
    <mat-icon class="nav-icon">folder</mat-icon>
    <div class="mat-caption">お礼の品</div>
  </div>
  <div
    matRipple
    class="nav-wrapper"
    [class.active]="activeDestination === 'my-profile'"
    (click)="changeDestination.emit('my-profile')"
  >
    <mat-icon svgIcon="profile" aria-hidden="false" class="nav-icon"></mat-icon>
    <div class="mat-caption">ﾏｲﾍﾟｰｼﾞ</div>
  </div>
</mat-toolbar>
