<mat-toolbar-row class="mat-elevation-z1 creator-profile">
  <button mat-icon-button [mat-dialog-close]="" class="back-button">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div>{{ title }}</div>
</mat-toolbar-row>

<div class="main-content">
  <table style="width: 100%;">
    <tr *ngFor="let user of userList" style="height: 60px;">
      <td style="width: 50px; text-align: center;" *ngIf="user.creatorIconUrl">
        <img
          src="{{ user.creatorIconUrl }}"
          style="height: 45px; border-radius: 7px; margin-top: 4px;"
        />
      </td>
      <td style="width: 50px; text-align: center;" *ngIf="!user.creatorIconUrl">
        <img
          src="assets/imgs/avatars/avatar{{ user.avatarId }}.png"
          style="height: 45px;"
        />
      </td>
      <td style="border-bottom: 0.5px solid lightgray;">
        <tr *ngIf="user.creatorIconUrl" (click)="openCreatorProfile(user)" matRipple>
          <td style="width: 100%;">
            <span>{{ user.nickname }}</span>
          </td>
          <td>
            <mat-icon>keyboard_arrow_right</mat-icon>
          </td>
        </tr>
        <tr *ngIf="!user.creatorIconUrl">
          <td style="width: 100%;">
            <span>{{ user.nickname }}</span>
          </td>
        </tr>
      </td>
    </tr>
  </table>
</div>
