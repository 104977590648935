import { Component, OnInit } from '@angular/core';
import { AppService } from './shared/services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private service: AppService) {}

  appReady$ = this.service.appReady$;

  ngOnInit() {
    this.service.initialize();
  }
}
